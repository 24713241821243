$body-bg-color: #e8e8e8;

// $site-primary-bg-color: #062f4f;
$site-primary-bg-color: #ab000f;
$site-primary-color: #f5f5f5;
$site-primary-link-color: #1a1010;

$header-equiry-title-bg-color: $site-primary-bg-color;
$header-equiry-title-color: $site-primary-color; 

$navigation-bg-color: #9a9a9a;
$navigation-link-color: #f5f5f5;
$navigation-active-link-color: #ffffff;
$navigation-active-link-border-color: $site-primary-bg-color;
$navigation-hover-link-color: #ffffff;
$navigation-hover-link-border-color: $site-primary-bg-color;

$footer-bg-color: $site-primary-bg-color;
$footer-text-color: $site-primary-color;

$btn-primary-color: $site-primary-bg-color;